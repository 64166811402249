// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/batote1.jpg";
import baderwah2 from "../../img/batote3.jpg";
import religiousImage1 from "../../img/shanklpal.jpg";
import religiousImage2 from "../../img/Narsingh-Mandir-Ramnagar.jpg";
import historicalImage1 from "../../img/natha_top.jpg";
import FoodsImage3 from "../../img/chole-bature.jpg";
import FoodsImage4 from "../../img/Rajma_Chawal.jpg";


const Batote = {
    name: "Batote",
    description: "Batote",
    linkMap:"https://www.google.com/maps?q=Batote,Jammu",
    description1: "Batote is a picturesque town nestled in the Doda district of Jammu region, known for its scenic beauty and strategic location on the National Highway connecting Jammu to Srinagar.",
    p1: "The town is surrounded by lush green forests and offers stunning views of the Chenab river and the Himalayan ranges. It's a popular stopover for tourists traveling between Jammu and Srinagar, and serves as a gateway to various trekking routes and tourist destinations in the region.",
    
    // backgroundImage: url(${gurazBandiporaBackground}),
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Batote is well-connected by road and is approximately 125 kilometers away from Jammu. The nearest railway station is Udhampur, while Jammu Airport serves as the closest air connectivity.",
    about1: "Batote is famous for its cool climate, beautiful landscapes, and as a starting point for treks to the surrounding mountains. The town also has a rich cultural heritage, with several temples and historical sites.",
    about2: "The local cuisine of Batote includes traditional Jammu dishes as well as popular North Indian snacks. Visitors can enjoy a variety of foods at local restaurants and street food stalls.",
    about3: "Batote offers a serene environment for tourists looking for a peaceful retreat in the mountains. It's also a popular destination for adventure sports like trekking and paragliding.",
    about4: "",
    arrivalbycab1: "Traveling from Jammu to Batote by cab takes around 2 hours, offering travelers a scenic route through the mountains.",
    arrivalbycab2: "There are numerous cab services available in Jammu that provide direct transportation to Batote. Options include both private and shared cabs.",
    arrivalbycab3: "It's advisable to book your cab in advance, especially during the tourist season, to avoid any inconvenience.",
    arrivalbycab4: "The journey to Batote offers breathtaking views of the Himalayan foothills, making the drive a memorable experience.",
    cimate0: "Batote experiences a temperate climate, with distinct seasons that each offer a unique charm to the town.",
    cimate1: "Summers are mild and pleasant, making it a perfect time for sightseeing and outdoor activities.",
    cimate2: "The monsoon season brings refreshing rain, enhancing the natural beauty of the area with lush greenery.",
    cimate3: "Autumn sees mild weather and is an ideal time for visiting, with clear skies and comfortable temperatures.",
    cimate4: "Winters are cold, with the possibility of snow, offering picturesque views of snow-covered landscapes.",
    headingfood: "Must try in Batote",
    foodsName: ['Chole Bhature', 'Rajma Chawal'],
    foodsDescription: ["Chole bhature is a food dish popular in the northern areas of the Indian subcontinent. It is a combination of chana masala (spicy white chickpeas) and bhatura/puri, a deep-fried bread made from maida. Chole bhature is often eaten as a breakfast dish, sometimes accompanied with lassi.", "A staple dish in the Jammu region, Rajma Chawal is red kidney beans cooked in a thick gravy with a blend of spices, served with steamed rice. It's a comfort food loved by locals and visitors alike."],
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Prem Palace ","Homefresh Hotel & Resort","NA","NA" ],
    hotelsPhone: ["NA ","+91-99069-55946","NA" ,"NA"],
    hotelsViewLinks: ["https://www.booking.com/hotel/in/prem-palace-patnitop/ ", "https://homefresh-resort.business.site/","","" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Prem Palace, Batote, Udhampur, Jammu and Kashmir 182128","https://www.google.com/maps?q=Homefresh Hotel & Resort"],
    religiousPlaces: ["Shankhpal Shrine"],
    religiousPlacesDescription: ["The Shankhpal shrine in Ramban district has been attracting the devotees in larger numbers, with each passing year and politicians from its adjoining Ramban, Reasi, Udhampur and Doda districts.The Shankhpal (one of the Naag Devtas) shrine temple perched in a vast plain meadow at an altitude of about 10,000 ft fsl(from sea level), is three hour trek away from Sanasar"],
    historicalPlaces: ["Nathatop "],
    historicalPlacesDescription: ["Nathatop has become a popular destination for tourists seeking tranquility, natural beauty, and adventure. At an elevation that offers cool and pleasant weather throughout the year, visitors flock to Nathatop to enjoy activities such as paragliding, trekking, and snowboarding during the winter months."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default Batote;