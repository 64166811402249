// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/chakkan1.jpg";
import baderwah2 from "../../img/chakkan2.jpg";
import religiousImage1 from "../../img/dehrakigali.jpg";
import religiousImage2 from "../../img/shiv-khori.avif";
import historicalImage1 from "../../img/poonch fort.webp";
import historicalImage2 from "../../img/noori chamb.jpeg";
import FoodsImage3 from "../../img/Dum-Aloo.jpg";
import FoodsImage4 from "../../img/kabergah.jpg";


const chakkanData= {
    name: "Chakkan Da Bagh",
    
    description: "Chakkan Da Bagh",
    linkMap:"https://www.google.com/maps?q=Chakan Da Bagh, Q25P+W8W, Polas, 185101", 
    description1: "LOC Trade Centre, also known as Chakkan da Bagh in Poonch, is situated, on the cross-LOC Poonch-Rawlakote road, approximately 8 km south of Poonch Town. This center plays a crucial role in facilitating trade between the two sides of the Line of Control. Loaded trucks from Pakistan-occupied Kashmir (POK) and Jammu/Poonch arrive here for unloading and further transportation. Additionally, passengers from divided families crossing the LOC undergo security checks at this location.",
    p1: "Chakan Da Bagh is known for hosting the cross-border bus service between Poonch in Indian-administered Jammu and Kashmir and Rawalakot in Pakistan-administered Azad Kashmir. This bus service, known as the Poonch-Rawalakot bus, provides an important link for divided families and facilitates people-to-people contact between the two regions.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "The distance between Jammu city in the Indian union territory of Jammu and Kashmir and Chakan Da Bagh, which is a border crossing point between India and Pakistan, can vary depending on the specific route taken.By road, the distance from Jammu city to Chakan Da Bagh is approximately 200 to 220 km (about 124 to 137 miles), depending on the exact starting point in Jammu and the specific route chosen. The journey typically takes around 5 to 6 hours by car, depending on road conditions and traffic.",
    about1: " Chakan Da Bagh holds strategic importance due to its location near the Line of Control, which separates the Indian-administered Jammu and Kashmir from the Pakistan-administered Azad Kashmir.",
    about2: "The crossing at Chakan Da Bagh facilitates trade and travel between the two sides. It serves as a crucial point for cross-border movement of goods and people, fostering economic and cultural exchanges. ",
    about3: "Given its proximity to the Line of Control, Chakan Da Bagh is subject to heightened security measures. Both Indian and Pakistani security forces are stationed in the area to monitor movements and ensure border security.",
    about4: "While Chakan Da Bagh primarily serves as a border crossing point and trade route, it also attracts tourists due to its picturesque surroundings and historical significance. Visitors often stop by the area to witness the scenic beauty of the Poonch region and learn about its rich cultural heritage. ",
    arrivalbycab1: "Visitors can reach the LOC Trade Centre by road. The Poonch-Rawlakote road provides access, and the center is well-connected for the convenience of traders and passengers.  ",
    arrivalbycab2: "The trade center is approximately 8 km south of Poonch Town, making it easily accessible for both locals and tourists. ",
    arrivalbycab3: "It's important to note that Chakan Da Bagh is a sensitive border area, and access may be restricted or regulated due to security concerns. Travelers are advised to check with local authorities or security agencies for the latest information and any travel advisories before planning a trip to Chakan Da Bagh. ",
    arrivalbycab4: " ",
    cimate0: "Poonch generally experiences a temperate climate with pleasant summers and cold  winters. It's advisable to check weather conditions before visiting, especially during the winter months. ",
    cimate1: "Summer (March to June): The summer season in Poonch district is usually warm and dry. Daytime temperatures can range from 25°C to 35°C (77°F to 95°F). The weather is generally pleasant, making it suitable for outdoor activities.  ",
    cimate2: "Monsoon (July to September): Poonch district experiences the monsoon season during these months.  Rainfall occurs, often in the form of heavy showers or thunderstorms. The region becomes lush and green due to the precipitation. ",
    cimate3: "Autumn (October to November): Autumn brings mild temperatures and clear skies to Poonch district. Daytime temperatures range from 15°C to 25°C (59°F to 77°F). It's an ideal time for outdoor excursions and sightseeing. ",
    cimate4: "Winter (December to February): Winters in Poonch district are cold and can be harsh, especially in higher altitude areas. Daytime temperatures range from 5°C to 15°C (41°F to 59°F), while night time temperatures can drop below freezing. Snowfall is common, particularly in the higher elevations, creating stunning winter landscapes. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Sain Inn ","River view Hotel","NA","NA" ],
    hotelsPhone: ["NA","NA","NA","NA" ],
    hotelsViewLinks: ["https://hotel-sain-inn.business.site", "NA","NA","NA" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Sain Inn, 17 RCR Ward no 17, near DIET complex, Jammu and Kashmir 185101","https://www.google.com/maps?q=River view Hotel, Poonch, Jammu and Kashmir 185101","https://www.google.com/maps?q=","https://www.google.com/maps?q=",],
    headingfood: "Must try in Chakkan Da Bagh",
    foodsName: ['Dum Aloo', 'Kabargah'],
    foodsDescription: [ "Dum Aloo is a comforting potato dish where baby potatoes are simmered in a thick gravy made with yogurt, tomatoes, and a blend of spices. The dish is often garnished with fresh coriander leaves and served with rice or flatbread. ","Kabargah is a famous Kashmiri delicacy made from tender pieces of lamb ribs marinated in yogurt and spices, then deep-fried until crispy and golden brown. It is often served as a starter or appetizer during special occasions and festivals. "],
    religiousPlaces: ["Dehra Ki Gali","Shiv Khori"],
    religiousPlacesDescription: ["Situated about 40 km from Poonch Town, Dehra Ki Gali is a beautiful mountain pass known for its stunning landscapes. It's a popular spot for trekking and offers breathtaking views of the Pir Panjal range.","Located around 80 km from Poonch, Shiv Khori is a famous cave shrine dedicated to Lord Shiva. The cave is adorned with naturally formed stalagmites and is a significant pilgrimage site."],
    historicalPlaces: ["Poonch Fort","Noori Chamb"],
    historicalPlacesDescription: ["Explore the historical Poonch Fort, located in close proximity to the LOC Trade Centre. The fort offers a glimpse into the region's rich history and provides panoramic views of the surrounding landscapes.","Approximately 20 km from the LOC Trade Centre, Noori Chamb is a scenic spot known for its lush greenery and serene atmosphere. It's an ideal place for nature lovers and those seeking tranquility."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default chakkanData;