// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/nilnag-lake.jpg";
import baderwah2 from "../../img/nilnag_lake2.jpg";
import religiousImage1 from "../../img/Charar-e-Sharief.jpg";
import historicalImage1 from "../../img/tosamaidan.jpg";
import historicalImage2 from "../../img/sang-safad.jpg";
import FoodsImage3 from "../../img/Kashmiri_Modur_Pulao_With_Pomegranate_-1.jpg";
import FoodsImage4 from "../../img/shufta.webp";


const nilnag = {
    name: "Nilnag Lake",
    description: "Nilnag Lake a Tranquil Oasis in Yusmarg",
    linkMap: "https://www.google.com/maps?q=Nilnag Lake",
    description1: "Nestled within the pristine landscapes of Yusmarg, Nilnag Lake stands as a serene oasis, inviting travelers into a realm of tranquility and natural splendour. Located in the picturesque Pir Panjal range of the Himalayas in the union territory of Jammu and Kashmir, India, Nilnag Lake is a hidden gem that mirrors the surrounding emerald meadows and snow-capped peaks. At an  elevation of approximately 2,400 meters (7,874 feet) above sea level, this pristine waterbody offers a secluded retreat for those seeking solace amidst unspoiled beauty..",
    p1: "Nilnag Lake is not just a natural wonder; it is a sanctuary of tranquility that invites travelers to immerse themselves in the beauty of the Kashmiri countryside. Surrounded by dense forests and snow-capped mountains, the lake exudes a sense of serenity and calmness, making it an ideal destination for nature lovers and photography enthusiasts alike.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Nilnag Lake is nestled within Yusmarg, which is situated approximately 47 kilometers southwest of Srinagar. The journey to Yusmarg from Srinagar is a scenic drive, providing visitors with breathtaking views of the Pir Panjal range. Once in Yusmarg, Nilnag Lake is easily accessible,  either by a serene walk or pony rides through the enchanting meadows.",
    about1: "This idyllic destination offers visitors the opportunity to reconnect with nature and indulge in leisurely activities such as boating, picnicking, and birdwatching. The tranquil ambiance of Nilnag Lake provides a perfect setting for relaxation and rejuvenation amidst the scenic beauty of the Kashmir Valley.",
    about2: "Yousmarg: Yousmarg, often referred to as the Meadow of Yesul in local language, is a charming hill station nestled in the lap of the Pir Panjal Range. It's renowned for its pristine natural beauty, verdant meadows, and panoramic views of snow-capped mountains. Yousmarg is a perfect destination for nature lovers, trekkers, and adventure enthusiasts.",
    about3: "Boating: Boating facilities are available at Nilnag Lake, allowing visitors to explore the pristine waters while enjoying panoramic views of the surrounding landscapes. Rowboats and paddleboats can be rented from nearby vendors, providing a unique opportunity to experience the tranquility of the lake up close.",
    about4: "Birdwatching: Nilnag Lake is home to a diverse range of bird species, making it a paradise for birdwatchers. Visitors can spot a variety of migratory and resident birds along the lakeshore and in the surrounding forests, including waterfowl, songbirds, and birds of prey.",
    arrivalbycab1: " If you're planning to travel from Srinagar to Nilnag Yousmarg by cab, here's some guidance on how you can arrange your journey ",
    arrivalbycab2: "Pre-booked Cab: You can arrange for a pre-booked cab service either through a local travel agency, hotel, or online booking platforms. This way, you can ensure that a cab will be waiting for you at your desired location and time. ",
    arrivalbycab3: "Taxi Service: Taxis are readily available in Srinagar, and you can hire one directly from taxi stands or through taxi services operating in the city. Make sure to negotiate the fare beforehand and inform the driver of your destination in Nilnag Yousmarg.",
    arrivalbycab4: "App-based Cab Services: Some app-based cab services might operate in Srinagar, allowing you to book a cab conveniently through a mobile app. Check if the service covers the route to Nilnag Yousmarg and make your booking accordingly. ",
    cimate0: "The climate in the Nilnag Yousmarg area can be categorized as temperate. Here's a general overview of the climate throughout the year: ",
    cimate1: "Spring (March to May): Spring in Nilnag Yousmarg brings mild temperatures, with daytime highs ranging from 10°C to 20°C (50°F to 68°F). The weather gradually warms up, and the landscape comes alive with blooming flowers and lush greenery.  ",
    cimate2: "Summer (June to August): Summer is pleasant in Nilnag Yousmarg, with daytime temperatures ranging from 20°C to 30°C (68°F to 86°F). It's the ideal time for outdoor activities such as trekking, picnicking, and sightseeing. The weather is generally sunny, but occasional rain showers may occur, so it's advisable to carry rain gear. ",
    cimate3: "Autumn (September to November): Autumn brings cooler temperatures to Nilnag Yousmarg, with daytime highs ranging from 10°C to 20°C (50°F to 68°F). The landscape transforms into vibrant hues of red, orange, and yellow as the leaves change color, making it a picturesque time to visit. ",
    cimate4: " ",
    headinghotels: "List Of Hotels",
    hotelsName: ["NA", "NA", "NA"],
  hotelsPhone: ["NA", "NA", "NA"],
  hotelsViewLinks: ["NA", "NA", "NA"],
  hotelsMapsLinks: ["NA", "NA", "NA"],
    headingfood: "Must try in Nilnag ",
    foodsName: ['Modur Pulao (Veg)', 'Shufta (Veg)'],
    foodsDescription: [ "Sweetened Kashmiri rice prepared with cinnamon, a touch of saffron, and garnished with fruits and nuts. ","A sweet delicacy made from a mix of dry fruits, paneer, and a sugary syrup infused with saffron and other spices. "],
    religiousPlaces: ["Charar-e-Sharief"],
    religiousPlacesDescription: ["  Approximately 30 kilometers from Yousmarg, Charar-e-Sharief is a historic town known for its ancient shrines and religious significance. The town's main attraction is the Shrine of Sheikh Noor-ud-din Noorani, a revered Sufi saint. Visitors can explore the shrine and experience the spiritual atmosphere of the town. "],
    historicalPlaces: ["Tosa Maidan","Sang-e-Safed Picnic Spot"],
    historicalPlacesDescription: ["Situated near Yousmarg, Tosa Maidan is a vast meadow surrounded by lofty peaks and dense forests. It offers panoramic views of the Kashmir Valley and is an ideal destination for camping, picnicking, and trekking.","Located near Nilnag Lake, this scenic picnic spot offers stunning views of the surrounding mountains and meadows. Visitors can enjoy a picnic amidst the natural beauty or indulge in activities like birdwatching and photography."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default nilnag;