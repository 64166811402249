import React, { useEffect, useState } from "react";
import "./gallery.css";
import a1 from "../img/kailash-kund.jpeg";
import  b1 from "../img/laldraman.jpg";
import c1 from "../img/shanklpal.jpg";
import d1 from "../img/mac.jpg";
import eco2 from "../img/baderwah2.jpg";
import eco3 from "../img/jantrondhar1.jpg";
import eco6 from "../img/gathlake.jpg";
import eco8 from "../img/siyad-baba1.jpg";
import mice2 from "../img/sanasar.jpg";
import mice3 from "../img/panjnara2.jpg";
import mice6 from "../img/apharwat-peak2.jpg";
import mice7 from "../img/nilnag-lake.jpg";
import bordertourism2 from "../img/chakkan2.jpg";
import bordertourism4 from "../img/uri1.jpg";
import bordertourism5 from "../img/tangdar1.jpg";
import bordertourism7 from "../img/pallanwala1.jpg";
import healthwellness1 from "../img/tattapani1.jpg";
import healthwellness4 from "../img/mantai-lake.jpg";
import healthwellness6 from "../img/tarser-lake2.jpg";
import healthwellness8 from "../img/bangus5.webp";
import adventure1 from "../img/astanmarg-1.jpg";
import adventure4 from "../img/warwan2.avif";
import adventure6 from "../img/padri-pass.jpg";
import adventure8 from "../img/patnitop.webp";
import heritage2 from "../img/baderwahfort2.jpg";
import heritage4 from "../img/sheeshmahal2.jpg";
import heritage6 from "../img/Shalimar-in-Srinagar.jpg";
import heritage7 from "../img/pari-mahal.jpg";
import rural2 from'../img/ramnagar2.jpg';
import rural3 from "../img/batote1.jpg";
import Heder from "../Dashboard/custom-header";
import Footer from "../Dashboard/footer";
import { useParams } from "react-router-dom";

const Gallery = ({ destination, pictures }) => {
  const { location } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);  

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const locationParam = queryParams.get("location");

    if (locationParam) {
      setSelectedOption(locationParam);
    }

   
  }, [location]);

  const openBlogModal = (slide) => {
    const relatedBlogs = blogItems.find((blog) => blog.slide === slide);
    setSelectedBlog(relatedBlogs);
  };

  const closeBlogModal = () => {
    setSelectedBlog(null);
  };


//   const blogItems = [
//     { title: "KAILASH KUND",
//     //  content: "It is famous place of pilgrimage and the Kund is a big lake of cold crystal Clearwater having circumference of 1.5 miles. Kailash Kund also Known as Vasuki kund, is abode of Nagraj Vasuki and is situated at a height of 14500ft. above sea level. Every year thousands of pilgrims pay Darshan, which commences on the 14th day after Shravan Purnima. The yatra starts from Gatha bhaderwah .",
//      image: a1 ,slide: 1
//     },
  
//     { title: "LAL DRAMAN",
//     //  content: "It is a beautiful spot 16 km North-West of Doda town. Amidst lofty and fir trees near village Sazan. It has a lot of tourism significance and offers adventure tourism.",
//      image: b1 ,slide: 1
//     },
//     { title: "SANKPAL",
//     //  content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
//      image: c1,slide: 1
//     },
//     { title: "MACHAIL",
//     // content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
//     image: d1, slide: 1
//    },
//    { title: "KAILASH KUND",
//    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
//    image: a2, slide: 2
//   },
//   { title: "LAL DRAMAN",
//   // content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
//   image: b2, slide: 2
//  },
//  { title: "SANKPA",
//     // content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
//     image: c2,slide: 2
//    },
//    { title: "MACHAIL",
//   //  content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
//    image: d2, slide: 2
//   },
 
    
//   ];
const blogItems =
selectedOption === "pilgrim"
    ? [
        { title: "KAILASH KUND", image: a1, slide: 1, },
        { title: "LAL DRAMAN", image: b1, slide: 2 },
        { title: "SANKPAL", image: c1, slide: 3 },
        { title: "MACHAIL", image: d1, slide: 4 },
      ]
    : selectedOption === "ecotourism"
    ? [
      { title: "BADERWAH", image: eco2, slide: 2 },
      { title: "JANTRON DHAR", image: eco3, slide: 3 },
      { title: "GATHA LAKE", image: eco6, slide: 6 },
      { title: "SIYED BABA WATERFALL", image: eco8, slide: 8 }
      ]
    : selectedOption === "mice"
    ? [
      
      { title: "SANASAR", image: mice2, slide: 2 },
      { title: "PANJNARA", image: mice3, slide: 3 },
      { title: "APHARWAT PEAK", image: mice6, slide: 6 },
      { title: "NILNAG LAKE", image: mice7, slide: 7 },
      ]
    : selectedOption === "bordertourism"
    ? [
      
      { title: "CHAKKAN DA BAGH", image: bordertourism2, slide: 2 },
      { title: "URI", image: bordertourism4, slide: 4 },
      { title: "TANGDHAR", image:bordertourism5, slide: 5},
      { title: "PALLANWALA SECTOR", image: bordertourism7, slide: 7 },
      ]
    : selectedOption === "healthwellness"
    ? [
      { title: "TATTA PANI", image: healthwellness1, slide: 1, }, 
      
      { title: "MANTALIA TEMPLE", image: healthwellness4, slide: 4},
      { title: "TARSER LAKE", image: healthwellness6, slide: 6 },
      { title: "BANGUS VALLEY", image: healthwellness8, slide: 8 }
      ]
    : selectedOption === "adventure"
    ? [
      { title: "ASTANMARG", image: adventure1, slide: 1, },
      { title: "WARWAN VALLEY", image: adventure4, slide: 4 },
      { title: "PADRI", image: adventure6, slide: 6 },
      { title: "PATNITOP", image: adventure8, slide:8}
      ]
    : selectedOption === "heritage"
    ? [
      { title: "BADERWAH FORT", image: heritage2, slide:2 },
      { title: "SHEESH MAHAL", image: heritage4, slide: 4 },
      { title: "SHALIMAR BAGH", image: heritage6, slide: 6 },
      { title: "PARI MAHAL", image: heritage7, slide: 7},
      ]
      : selectedOption === "rural"
    ? [
      { title: "RAMNAGAR", image: rural2, slide: 2 },
      { title: "BATOTE", image: rural3, slide: 3 },
     
      ]
    // Add more cases for other options as needed
    : [];
  return (

    <div>
       <Heder />
        
<div class="container">
    <div class="section-header">
      <h class="head">GALLERY</h>
      {/* <p class="para">Paryatan saathi</p> */}
    </div>
    <div class="blog-content">
      <div class="row">
         {/* Map over your blog items */}
         {blogItems.map((blog, index) => (
        <div class="col-md-3 col-sm-6" key={index}>
          <div className="single-blog-item" onClick={() => openBlogModal(blog.slide)}>
            <div class="single-blog-item-img">
            <div  style={{ width: '100%', height: '207px',display: 'block'  }} className="image-container">
              <img src={blog.image} alt="" style={{ width: '100%', height: '207px', display: 'block' }}  className="blog-image"
                />
              </div>
              </div>
            <div class="single-blog-item-txt">
              <h2><a href="#!">{blog.title}</a></h2>
            </div>
          </div>
        </div>
         ))}
       
      </div>
    </div>
    {/* Modal for displaying detailed blog content */}
    {selectedBlog && (
        <div className="blog-modal-overlay" onClick={closeBlogModal}>
          <div className="blog-modal-content">
            <h2>{selectedBlog.title}</h2>
            <img src={selectedBlog.image} alt=""  style={{ width: '100%', height: '100%',}} />
            <p>{selectedBlog.content}</p>
            <button onClick={closeBlogModal}>Close</button>
          </div>
        </div>
      )}
      </div>
      <Footer/>
     </div>
  );
};
export default Gallery;