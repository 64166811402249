

import shalimarImage1 from "../../img/shalimar-bagh-srinagar1.jpg";
import shalimarImage2 from "../../img/Shalimar-in-Srinagar.jpg";
import religiousImage1 from "../../img/merak shah.jpg";
import religiousImage2 from "../../img/hazratbal.jpg";
import historicalImage1 from "../../img/nishat-bagh-3.jpg";
import historicalImage2 from "../../img/Dal-Lake.jpg";
import FoodsImage3 from "../../img/mutton-roganjosh.webp";
import FoodsImage4 from "../../img/yakhni.jpg";
import FoodsImage5 from "../../img/gushtaba.jpg";

const shalimarBagh = {
  name: "Shalimar Bagh",
  description: "Shalimar Bagh",
  linkMap: "https://www.google.com/maps?q=Shalimar Bagh, Srinagar",
  description1: "Shalimar Bagh, also known as Shalimar Gardens, is a Mughal garden located in Srinagar, Jammu and Kashmir, India. It is the largest Mughal garden in the Kashmir Valley and is situated on the right bank of the Dal Lake.",
  p1: "The garden was built by Mughal Emperor Jahangir for his wife Nur Jahan in 1619. It is spread over an area of approximately 31 acres and is divided into three terraces, each representing a different style of gardening.",
  images: [shalimarImage1, shalimarImage2], // Add images array
  distanceFromSrinagar: "Situated approximately 15 kilometers northeast of Srinagar city center, Shalimar Bagh is easily accessible by road.",
  about1: "The garden is famous for its stunning architecture, lush greenery, and beautiful fountains. It is a popular tourist attraction and is visited by thousands of tourists every year.",
  about2: "The name 'Shalimar' means 'abode of love', and the garden is indeed a symbol of love and romance. It is a perfect destination for nature lovers, photographers, and history enthusiasts.",
  about3: "Visitors can enjoy leisurely strolls amidst the fragrant flowers, relax by the fountains, or simply soak in the peaceful ambiance of the surroundings.",
  about4: "Shalimar Bagh is a historic garden located in Srinagar, Jammu and Kashmir, India. It is known for its stunning Mughal-era architecture and beautiful terraced gardens. The garden was built in the 17th century by Mughal Emperor Jahangir for his wife, Nur Jahan. Shalimar Bagh is one of the most popular tourist attractions in Kashmir and is renowned for its intricate design, colorful flower beds, and serene ambiance. It's a testament to the rich cultural heritage of the region and attracts visitors from all around the world.",
  arrivalbycab1: "To arrange transportation to Shalimar Bagh in Srinagar, you can follow these steps:",
  arrivalbycab2: "Find Local Transport Options: Look for local cab services or taxi stands in Srinagar. You can search online, ask your accommodation provider for recommendations, or inquire with locals.",
  arrivalbycab3: "Booking: Once you have identified a suitable cab service, proceed to book your cab. Provide them with details such as your name, pickup location, desired pickup time, and any specific requirements you may have." ,
  arrivalbycab4: "The cab should pick you up from your designated location in Srinagar and transport you to Shalimar Bagh.",
  cimate0: "The climate around Shalimar Bagh in Srinagar, Jammu and Kashmir, is typically cold in winters and mild in summers, with distinct seasons throughout the year.",
  cimate1: "Summer (June to August): The summer months are mild and pleasant, with temperatures ranging from around 10°C to 30°C (50°F to 86°F). It is an excellent time to visit Shalimar Bagh as the gardens are in full bloom, and the weather is ideal for outdoor activities." ,
  cimate2: "Autumn (September to November): Autumn brings cooler temperatures and vibrant foliage colors, making it a picturesque time to visit the garden. The weather is crisp and comfortable, perfect for exploring the outdoors." ,
  cimate3: "Winter (December to February): Winter in Srinagar is cold, with temperatures often dropping below freezing, especially at night. Snowfall is common during these months, adding to the beauty of Shalimar Bagh. Daytime temperatures range from 0°C to 10°C (32°F to 50°F)." ,
  cimate4: "Spring (March to May): Spring is a delightful time to visit Shalimar Bagh as the weather gradually warms up, and the gardens come to life with blossoming flowers and lush greenery.",
  headinghotels: "List Of Hotels",
  hotelsName: ["Basera Residency", "Hotel Silver Inn", "Hotel Royal Batoo", "The Montreal"],
  hotelsPhone: ["08803850213", "NA", "+91-194-2473031", "07889883764"],
  hotelsViewLinks: ["http://www.baserasrinagar.in/", "https://hotelsilverinnkashmir.com/", "https://www.hotelroyalbatoo.com/", "https://www.themontreal.in/"],
  hotelsMapsLinks: ["https://www.google.com/maps?q=shahdad lane, Harwan Rd, Shalimar, Jammu and Kashmir 191123", "https://www.google.com/maps?q=near Shalimar Garden, Pazwalpura, Rainawari, Srinagar, Jammu and Kashmir 190025", "https://www.google.com/maps?q=Hotel Royal Batoo, Khayam Chowk, Dalgate Bridge, near Khyber Hospital, Srinagar, Jammu and Kashmir 190001", "https://www.google.com/maps?q=4VVH+G47, near SKAUST, Shalimar, meerak abad, Srinagar, Jammu and Kashmir 190025"],
  headingfood: "Must-Try in Srinagar",
  foodsName: ['Rogan Josh', 'Yakhni', 'Gushtaba'],
  foodsDescription: ["Rogan Josh is a signature Kashmiri dish made with tender mutton cooked in a rich gravy of yogurt, spices, and saffron. It is best enjoyed with steamed rice or Kashmiri naan.",
    "Yakhni is a traditional Kashmiri dish made with tender pieces of meat (usually chicken or lamb) cooked in a yogurt-based gravy with aromatic spices like fennel, cardamom, and cinnamon. It is a comforting and flavorful dish often served with rice.",
    "Gushtaba is a delectable Kashmiri meatball curry made with minced mutton balls cooked in a flavorful yogurt-based gravy. It is rich, creamy, and bursting with traditional Kashmiri spices."],
  religiousPlaces: ["Sayyid Meerakh Shah Kashani","Hazratbal Shrine"],
  religiousPlacesDescription: ["Sayyid Mirak Shah Kashani was a poet of substance who wrote devotional poems in Kashmiri language. The theme of his verses was Toheed (monotheism) and Ishq-e-Rasool (love of the Messenger of Allah)","The Hazratbal Shrine, popularly called Dargah Sharif, is a Muslim shrine located in Hazratbal locality of Srinagar in Jammu and Kashmir, India. It contains a relic, Moi-e-Muqqadas, the blessed hair of the Islamic prophet Muhammad"],
  historicalPlaces: ["Nishat Bagh","Dal – Lake"],
  historicalPlacesDescription: ["Nishat Bagh is a terraced Mughal garden built on the eastern side of the Dal Lake, close to Srinagar in the union territory of Jammu and Kashmir, India. It is the second largest Mughal garden in the Kashmir Valley. Nishat  Bagh , is also located on the bank of the Dal Lake. Nishat Bagh is Urdu, and means the Garden of Joy Garden of Gladness and Garden of Delight","Dal lake, originally known as Sureshvari Dal, is a lake in Srinagar, the summer capital of the Indian-administered Jammu and Kashmir in the disputed Kashmir region. It is an urban lake the second largest lake in Jammu and Kashmir, and the most visited place in Srinagar by tourists and locals. It is integral to tourism and recreation in the Kashmir valley and is variously known as the Lake of Flowers”."],
  foodsImages: [FoodsImage3,FoodsImage4,FoodsImage5],
  imagesforreligious: [religiousImage1,religiousImage2],
  imagesforhistorical: [historicalImage1,historicalImage2]
};

export default shalimarBagh;
