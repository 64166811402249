import React from "react";
import './navbar.css';
function App() {
  return (
    <div>
          {/* // <!-- Navbar Start --> */}
          <div class="container-fluid position-relative nav-bar p-0">
            
            <div class="container-lg position-relative p-0 px-lg-3 z-index" >
                <nav class="navbar navbar-expand-lg bg-light navbar-light  flex-column shadow-lg py-3 py-lg-0 pl-3 pl-lg-5 background-color-rgba">
                    <a a href="/" class="navbar-brand">
                        <h1 class="m-0 text-primary color-green"><span class="text-dark">PARYATAN</span>SAATHI</h1>
                    </a>
                    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                        <div class="navbar-nav ml-auto py-0">
                            <a  href="/" class="nav-item nav-link active " >HOME</a>
                            {/* <a href="about.html" class="nav-item nav-link">About</a> */}
                            <a href="#destination-section" class="nav-item nav-link">EXPLORE</a>
                            <a href="https://jammutourism.gov.in/ContactUs.html" class="nav-item nav-link">CONTACT US</a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        {/* // <!-- Navbar End --> */}
     </div>
     );
    }
    
    export default App;