// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/patnitop.webp";
import baderwah2 from "../../img/PATNItop.jpg";
import religiousImage1 from "../../img/naag-mandir.jpg";
import religiousImage2 from "../../img/sudh mahadev.jpg";
import historicalImage1 from "../../img/natha_top.jpg";
import FoodsImage3 from "../../img/kehwa.webp";
import FoodsImage4 from "../../img/mutton-roganjosh.webp";


const PatniTop = {
    name: "Patnitop",
    
    description: "Patnitop",
    linkMap:"https://www.google.com/maps?q=Patnitop",
    description1: " Patnitop is situated in the Shivalik range of the Himalayas, approximately 112 kilometers from Jammu city. It lies at an altitude of around 2,024 meters (6,640 feet) above sea level.",
    p1: "The hill station is known for its breathtaking natural beauty, with lush green meadows, dense pine forests, and panoramic views of the surrounding mountains. The landscape transforms beautifully with each season, making it a popular destination throughout the year.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Patnitop is well-connected by road and can be reached from Jammu city by bus, taxi, or private vehicle. The nearest railway station is Udhampur, approximately 45 kilometers away, and the nearest airport is Jammu Airport, about 112 kilometers away",
    about1: "  Patnitop offers various adventure activities for thrill-seekers, including trekking, camping, horse riding, and mountain biking. During the winter season, Nathatop becomes a hub for skiing and snowboarding enthusiasts.",
    about2: "The hill station has several shops and stalls selling local handicrafts, souvenirs, and traditional Kashmiri items like Pashmina shawls and Kashmiri embroidery. There are also restaurants and eateries offering delicious Kashmiri cuisine and other Indian dishes. ",
    about3: " Patnitop is a tranquil retreat nestled amidst nature's splendor, offering a perfect blend of scenic beauty, adventure, and cultural experiences for travelers seeking a refreshing getaway in the Himalayas.    ",
    about4: " ",
    arrivalbycab1: "The journey usually takes around 3 to 4 hours, depending on road conditions, traffic, and the specific route taken. ",
    arrivalbycab2: "You can easily find cab services in Jammu that offer trips to Patnitop. There are various cab operators and travel agencies in Jammu providing both private and shared cab services. ",
    arrivalbycab3: " You can book a cab in advance through online platforms, travel agencies, or directly contacting cab operators. Make sure to inquire about the availability, rates, and any specific requirements you may have for your journey.",
    arrivalbycab4: "Sit back, relax, and enjoy the scenic drive from Jammu to Patnitop. The route offers picturesque views of the Himalayan mountains and valleys, making for a memorable journey. ",
    cimate0: "The climate of Patnitop, like many hill stations in the region, experiences variations throughout the year.  ",
    cimate1: "Summer (March to June): Summer in Patnitop is mild and pleasant, making it the peak tourist season. Daytime temperatures typically range from 15°C to 25°C (59°F to 77°F). The weather is ideal for outdoor activities such as trekking, camping, and sightseeing. Nights can be cooler, with temperatures dropping to around 10°C (50°F).  ",
    cimate2: "Monsoon (July to September): Patnitop receives moderate to heavy rainfall during the monsoon season. Rain showers can be frequent, which may restrict outdoor activities. However, the lush greenery that follows the rain adds to the natural beauty of the region. Temperatures during this time range from 15°C to 20°C (59°F to 68°F). ",
    cimate3: "Autumn (October to November): Autumn is characterized by mild temperatures and clear skies. The weather is pleasant, with daytime temperatures ranging from 10°C to 20°C (50°F to 68°F). It's an excellent time to visit Patnitop for those who prefer fewer crowds and enjoy outdoor activities amidst the colorful foliage. ",
    cimate4: "Winter (December to February): Winter in Patnitop is cold and snowy, with temperatures dropping below freezing point. Daytime temperatures range from 0°C to 10°C (32°F to 50°F), while nighttime temperatures can plummet to sub-zero levels. Heavy snowfall is common during this season, transforming the region into a winter wonderland. Winter sports such as skiing and snowboarding are popular activities during this time. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Green Hill Residency ","Hotel Alpine JKTDC","Hotel Patnitop by EMT","Hotel Natraj International Patnitop" ],
    hotelsPhone: ["01992288111","NA","099069 62988" ,"01992 287 521"],
    hotelsViewLinks: ["https://hotelgreenhillresidency.in.net.mybusinesslistingprofile.com/", "NA","https://hotel-patnitop.business.site/?m=true","http://www.hotelnatrajinternational.com/" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=National Highway, Patnitop, Sarak, Jammu and Kashmir 182142","https://www.google.com/maps?q=38QJ+92M, NH 44, Sarak, Jammu and Kashmir 182141","https://www.google.com/maps?q=Hotel Patnitop by EMT, Hotel, Main Taxi Stand, Patnitop, Sarak, Jammu and Kashmir 182142","https://www.google.com/maps?q=Hotel Natraj International Patnitop, Nag Temple, Karlah, Patnitop, Jammu and Kashmir 182142"],
    headingfood: "Must try in Lolab Valley",
    foodsName: [' Kashmiri Kahwa', 'Rogan Josh'],
    foodsDescription: [ "traditional green tea with spices like saffron and cardamom ","The main ingredients for Rogan Josh typically include tender pieces of mutton (or lamb), yogurt, onions, tomatoes, garlic, ginger, and a blend of aromatic spices. The key spices used in Rogan Josh include Kashmiri red chili powder (which gives the dish its vibrant red color), fennel seeds, cumin seeds, cardamom, cloves, and cinnamon. "],
    religiousPlaces: ["Naag Temple","Sudh Mahadev Temple"],
    religiousPlacesDescription: ["This ancient temple dedicated to the serpent god, Naag Devta, is located in the Patnitop area. It is a significant religious site visited by devotees seeking blessings.","Situated around 120 kilometers from Patnitop, Sudh Mahadev Temple is a revered Hindu shrine dedicated to Lord Shiva. It features a naturally formed Shivling and attracts pilgrims throughout the year."],
    historicalPlaces: ["Nathatop",],
    historicalPlacesDescription: [" Located about 14 kilometers from Patnitop, Nathatop is a scenic spot known for its snow-covered slopes during the winter months. It offers opportunities for adventure activities like skiing, paragliding, and trekking.",],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default PatniTop;