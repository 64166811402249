// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/PANJNARA.jpg";
import baderwah2 from "../../img/panjnara2.jpg";
import religiousImage1 from "../../img/trikuta-vaishno.avif";
import historicalImage1 from "../../img/bhimgard-fort.jpg";
import FoodsImage3 from "../../img/dal.jpg";
import FoodsImage4 from "../../img/pulao.jpg";


const panjNara = {
    name: "PANJNARA",
    
    description: "PANJNARA",
    linkMap:"https://www.google.com/maps?q=Panjnara",
    description1: "Panjnara, a village in the Budhal tehsil of Rajouri district in Jammu &amp; Kashmir, India, is situated 10km away from the sub-district headquarter Budhal and 28km away from the district headquarter Rajouri. The village, with a total geographical area of 314 hectares, is surrounded by the scenic landscapes of the region.",
    p1: "located 28km away, serves as the nearest town for major economic activities, providing the residents of Panjnara access to essential services and opportunities. The village, with its rural charm and natural surroundings, offers a glimpse into the local life and culture of this part of Jammu &amp; Kashmir. The Pincode for the Panjnara village locality is 185233, facilitating postal services and communication within the area.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Road travel is the most common mode of reaching Panjnara. The village is connected to nearby towns and the district headquarters, Rajouri, by well-maintained roads. Visitors can hire taxis or use private vehicles to navigate through the scenic routes leading to Panjnara. The nearest major town, Rajouri, is approximately 28 km away and serves as a central hub for transportation and economic activities.",
    about1: "Panjnara is situated in the Budhal Tehsil of Rajouri district, close to the town of Rajouri. ",
    about2: " You can reach the village by road, with the nearest major town being Rajouri serving as the starting point.",
    about3: " ",
    about4: " ",
    arrivalbycab1: "Book in advance: Especially during peak season or holidays, pre-booking ensures availability and potentially better rates ",
    arrivalbycab2: "Enjoy the scenic journey: The drive from Rajouri offers picturesque landscapes and rural charm. ",
    arrivalbycab3: "Be prepared for unpredictable weather conditions, especially during monsoon season (July-September) or winter months (December-March). ",
    arrivalbycab4: "Explore homestays or staying with local families for a unique and immersive experience. ",
    cimate0: "Panjnara experiences a subtropical highland climate with distinct seasons: ",
    cimate1: " Summer (April-June): Warm to hot, with average temperatures ranging from 20°C to 30°C (68°F to 86°F). Occasional showers are possible. ",
    cimate2: "Monsoon (July-September): Moderate to heavy rainfall, with average temperatures around 25°C (77°F). ",
    cimate3: "Autumn (October-November): Pleasant, with average temperatures dropping from 20°C to 10°C (68°F to 50°F). Some snowfall possible at higher altitudes.",
    cimate4: "Winter (December-March): Cold, with average temperatures ranging from 5°C to -5°C (41°F to 23°F). Frequent snowfall, especially at higher altitudes. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["TARA HOTEL AND RESTURANT ","SR Hotel and Restaurant 2.0","NA","NA" ],
    hotelsPhone: ["+91-070063 51804","+91-7006756428","NA","NA" ],
    hotelsViewLinks: ["https://tara-hotel-and-restraunt.business.site/?utm_source=gmb&amp;utm_medium=referral", "https://www.jktdc.co.in/", ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Lolab Valley , Lolab Valley, Jammu and Kashmir"],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['Dal', 'Pulao'],
    foodsDescription: [ "Lentils cooked in various styles, offering a protein-rich and flavorful addition to meals. ","Fragrant rice dish cooked with spices and often featuring meat or vegetables. "],
    religiousPlaces: ["Vaishno Devi Temple"],
    religiousPlacesDescription: ["It's one of the most famous Hindu temples in India and is located in the town of Katra, which is relatively close to Panjnara. The temple is dedicated to Vaishno Devi, an incarnation of the Hindu Goddess Mahalakshmi. Devotees from all over the country visit this shrine, especially during the Navratri festival, to seek the blessings of the Goddess. The temple is situated atop the Trikuta Mountains and requires a trek of about 13 kilometers from the base camp in Katra to reach the shrine."],
    historicalPlaces: ["Bhimgarh Fort"],
    historicalPlacesDescription: ["Bhimgarh Fort, also known as Reasi Fort. Bhimgarh Fort is located in Reasi town, which is relatively close to Panjnara. This historical fort holds significance as it dates back to the medieval period and has been associated with various rulers and dynasties that ruled the region. The fort is situated atop a hill and offers panoramic views of the surrounding landscape. It served as a strategic stronghold for rulers of the region, including the Dogra rulers of Jammu and Kashmir. The architecture of the fort reflects a blend of Rajput and Mughal styles, showcasing the cultural and historical influences prevalent in the region."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default panjNara;