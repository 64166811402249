
// import React, { Component } from 'react'
import React from 'react';
import './footer.css'

function Footer() {
  return (
   <div>
     {/* <!-- Footer Start --> */}
     <div class="container-fluid bg-dark text-white-50 px-lg-5 pb-2 margin-top-90px" >
        <div class="row pt-5">
            <div class="col-lg-5 col-md-6 ">
                <a href="/" class="navbar-brand">
                    <h1 class="text-primary"><span class="text-dark">PARYATAN</span>SAATHI</h1>
                    
                </a>
                <p  class="p-footer">"Jammu & Kashmir: Where Nature's Grandeur Meets Cultural Splendor."</p>
                <h6 class="text-white text-uppercase mt-4 mb-3 letter-spacing-5px">Follow Us</h6>
                <div class="d-flex justify-content-start"> 
                    
                    <a class="btn btn-outline-primary btn-square mr-4" href="https://www.facebook.com/jandktdc/"><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-outline-primary btn-square mr-4" href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="twitter" title="Twitter" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
                    <path fill="currentColor" d="m.036 0 5.782 7.73L0 14.017h1.31l5.093-5.503 4.116 5.503h4.456L8.868 5.851 14.284 0h-1.31L8.283 5.068 4.493 0H.036Zm1.926.965H4.01l9.04 12.087h-2.047L1.962.965Z"></path>
                    </svg>
                    </a>
                    <a class="btn btn-outline-primary btn-square mr-4" href="https://www.youtube.com/@JKTourismOfficial"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 ">
            </div>
            <div class="col-lg-4 col-md-6  d-flex align-items-end">
                <div class="w-100">
                    <div class="input-group">
                    <p class="m-0 text-white-50">Designed & Developed by<a href="https://jakega.jk.gov.in/"> J&K e-Governance Agency</a></p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>
  
  );
}

export default Footer;
