import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import gurazBandipora1 from "../../img/kailashkund.jpg";
import gurazBandipora2 from "../../img/kailash-2.jpg";
import Religious1 from "../../img/gatha-temple.jpg";
import Religious2 from "../../img/padri_temple.webp";
import historical1 from "../../img/jai_valley.jpg";
import historical2 from "../../img/baderwah_fort.jpg";
import historical3 from "../../img/chenab.jpg";
import madri from "../../img/madri.jpg";
import rajmaChawal from "../../img/Rajma_Chawal.jpg";
import grilledTrout from "../../img/grilled-trout.jpg";
import kulcha from "../../img/kulcha.jpg";


const gurazData = {
    name: "Kailash Kund",
          
         
         
          description:" Kailash Kund",
          linkMap:"https://www.google.com/maps?q=Kailash Kund Bhaderwah, VMCQ+V48, Bhaderwah - Chamba Rd, Forest Block, Jammu and Kashmir 184206 Valley, Forest Block",
          description1: "It is famous place of pilgrimage and the Kund is a big lake of cold crystal Clearwater having circumference of 1.5 miles. Kailash Kund also Known as Vasuki kund, is abode of Nagraj Vasuki and is situated at a height of 14500ft. above sea level. Every year thousands of pilgrims pay Darshan, which commences on the 14th day after Shravan Purnima. The yatra starts from Gatha bhaderwah .",
          p1: "It also offers a host of exciting activities for adventure lovers like Camping, trekking, hiking, horse riding, etc. ",
          p2: "The Kailash Kund trail leads you through a scenic path of coniferous forests, dotted with pine, fir, and cedar trees. Navigate through rolling meadows, steep ascents, and rocky terrain before reaching the rugged plains at the base of Mt Kablas. Then, descend through the unique Seoj Dhar meadows, a landscape unlike any other. Don’t expect just green fields, as these meadows are speckled with smooth boulders of varying sizes, creating an otherworldly setting.”",
          // p3: "For adventure seekers, Gurez offers a variety of outdoor activities, from trekking through scenic trails to experiencing the thrill of river rafting in crystal-clear streams. Whether you're a history buff, a nature lover, or an adventure enthusiast, Gurez has something special to offer for every traveler.",    
          backgroundImage: `url(${gurazBandiporaBackground})`,
          images: [gurazBandipora1, gurazBandipora2, gurazBandipora2], // Add images array
          distanceFromSrinagar:"The distance from Jammu to Kailash Kund is approximately 218 kilometers along NH 44 and NH244. The driving time is estimated to be 5 hours and 33 minutes, depending on traffic conditions.",
          about1: "At 12,887 feet above sea level, the Kailash Kund lake in Bhaderwah, Doda district of Jammu, awaits. Shrouded in mystery and rich with cultural lore, this holy site attracts many seeking spiritual enlightenment.”",
          about2: "Pilgrims flock to Bhaderwah town’s Kailash Kund for the annual three-day yatra in honor of the deity Vasuki Nag. This spiritual walk, held between August and September, has been observed for thousands of years. The yatra typically falls in the last week of August according to the Hindu calendar, with exact dates varying annually. To participate in this cultural and traditional experience, check local news sources in Jammu for confirmed dates.”",
          about3: "Despite its mysterious reputation, the trek to Kailash Kund leads to the stunning base of Mt. Kablas (as referred to by locals). The mile-wide, the grey lake sits amidst a rugged landscape. The journey begins at the Vasuki Nag temple in Gatha village, Bhaderwah, and takes pilgrims through a series of villages to the picturesque Seoj Dhar meadows. Immerse yourself in the local folklore with a story behind every rest stop and temple visited along the way. Ideal for those with a passion for cultural exploration, the pilgrimage, and yatra offer unforgettable experiences.",
          // about4: "Gurez takes pride in its meticulously manicured gardens, reminiscent of a bygone era. The region features botanical wonders, including the Garden of Tranquility and the Royal Bloom Gardens, where vibrant flora dances with the wind. These lush havens showcase Gurez's commitment to preserving its natural beauty and offering a tranquil retreat for visitors.",
          arrivalbycab1: " Kailash Kund is approximately 230 kilometers from Srinagar, the summer capital of Jammu and Kashmir. Kailash Kund is approximately 230 kilometers from Srinagar, the summer capital of Jammu and Kashmir.",
        arrivalbycab2: "If opting for cab travel, consider pre-booking online for a hassle-free journey, especially during peak season.",
          cimate2: "Kailash Kund experiences a varied climate.",
          cimate3: "Summer (April-June): Pleasant temperatures ranging from 15°C to 25°C, ideal for outdoor activities",
          cimate4: "Monsoon (July-September): Moderate rainfall, averaging 75 cm, adds to the region's lush greenery.",
          cimate5: "Autumn (October-November): Temperatures gradually decline, creating a vibrant display of fall foliage.",
          cimate6: "Winter (December-March): The region transforms into a winter wonderland, with temperatures dipping below freezing.",
          headinghotels:"List Of Hotels",
          hotelsName:["THE SAZ VALLEY RESORT","Dudu Valley Glamping resorts","TPR Hotel & Restaurant","TAMARA RESORT"],
          hotelsPhone:["099060 74475","NA","070060 03694","060055 20530",],
          hotelsViewLinks:["NA","NA","NA","NA",""],
          hotelsMapsLinks:["https://www.google.com/maps?q=THE SAZ VALLEY RESORT, Bani Basholi Road Nalthi, Bhaderwah, Jammu and Kashmir 182222","https://www.google.com/maps?q=Dudu Valley Glamping resorts, VGH7+9PP, near Vasuki Naag temple, Dudu, Jammu and Kashmir 182128","https://www.google.com/maps?q=TPR Hotel & Restaurant, Link road, Bhaderwah, Jammu and Kashmir 182222","https://www.google.com/maps?q=TAMARA RESORT, WPW9+V28, Forest Block, Bhaderwah, Jammu and Kashmir 182222"],
          headingfood: "Must try in Gurez",
          foodsName:['Madra','Rajma Chawal','Grilled Trout Fish','Kulcha'],
          foodsDescription:["  Madra is a curd and legume based curry. Usually the beans that are added in madra recipe are chickpeas (safed chana), kidney beans (rajma) and black eyed beans (rongi, lobia). Madra is one of the dishes that is served in food feast along with various other dishes","A classic North Indian dish of kidney beans cooked in a rich gravy, typically served with rice.","Freshly caught trout, a type of salmonid fish known for its delicate flavor and firm texture, is seasoned with aromatic spices and herbs. The fish is then grilled to perfection over charcoal or wood fire, infusing it with a smoky aroma and locking in its natural juices. The dish is typically served hot with lemon wedges, chutney, and sometimes steamed rice or salad"," A leavened flatbread stuffed with spiced potato masala or other delicious fillings, perfect for a quick bite."],
          religiousPlaces: ["GATHA TEMPLE", "PADRI TEMPLE"],
          religiousPlacesDescription: ["An ancient temple dedicated to Lord Shiva, around 17 km from Bhaderwah Fort and serving as the starting point for the Kailash Kund trek. Holds significant religious importance for pilgrims undertaking the yatra. Offers a peaceful ambiance and a glimpse into the region's rich cultural heritage. "," An ancient temple dedicated to Lord Shiva, approximately 28 km from Bhaderwah Fort, nestled amidst serene forests. Offers a spiritual atmosphere and stunning views of the surrounding mountains. A sacred heaven for devotees and nature lovers alike."],
          historicalPlaces: ["JAI  VALLEY", "BHADERWAH FORT", "CHENAB RIVER"],
          historicalPlacesDescription: ["Jai Valley a beautiful valley known for its apple orchards and scenic landscapes. Jai valley is an Lush green meadow carpeted with wildflowers, particularly during the summer months, paint a picture of serene beauty. Imagine rolling hills adorned with vibrant blooms, offering a heaven for nature enthusiasts and photographers alike.          ","A historical fort dating back to the 17th century, perched atop a hill and offering panoramic views of the Bhaderwah Valley. Explore its ramparts, bastions, and ancient structures, stepping back in time.","A major river flowing through Jammu and Kashmir, providing exciting opportunities for white-water rafting and other water activities amidst the Himalayan backdrop. Perfect for adventure seekers. "],
          foodsImages:[madri,rajmaChawal,grilledTrout,kulcha],
          imagesforreligious: [Religious1,Religious2 ], // Add images array for religious
          imagesforhistorical: [historical1,historical2,historical3], // Add images array for historical
  };
  
  export default gurazData;