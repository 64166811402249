// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/ramnagar1.jpg";
import baderwah2 from "../../img/ramnagar2.jpg";
import religiousImage1 from "../../img/pingladevi.jpg";
import religiousImage2 from "../../img/Narsingh-Mandir-Ramnagar.jpg";
import historicalImage1 from "../../img/seeshmahal.png";
import FoodsImage3 from "../../img/kaladi.jpg";
import FoodsImage4 from "../../img/Rajma_Chawal.jpg";


const Ramnagar = {
    name: "Ramnagar",
    
    description: "Ramnagar",
    linkMap:"https://www.google.com/maps?q=Ramnagar",
    description1: "Ramnagar is a quaint town nestled in the Udhampur district of Jammu. Known for its historical significance and serene surroundings, Ramnagar offers a tranquil escape from the hustle and bustle. ",
    p1: "Ramanagar is a historical small town situated in Udhampur District of Jammu and Kashmir. Ramnagar is named after its last ruler, King Ram Singh of Ramanagar. The King' Palace is also in the town. It has been taken over by Archeological Survey of India for maintenance and preservation. Inside the palace there is one sheesh mahal or a kind of hall where we can still see the painting of different types some are of the queens. Ramnagar is mostly a hilly area. Seoz glaciers and Samna Banj hills are some of the very high altitude areas.",
    // backgroundImage: url(${gurazBandiporaBackground}),
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Ramnagar is accessible by road and can be reached from Jammu via National Highway. The nearest railway station is in Udhampur, making it convenient for travelers to visit,the nearest airport is Jammu Airport, and its about 120 kilometers  away from jammu",
    about1: "Ramnagar offers a variety of activities for visitors, including trekking in the nearby forests, visiting ancient temples, and exploring historical sites. The town is also known for its local handicrafts and traditional cuisine.",
    about2: "Visitors can enjoy local delicacies and traditional Dogra cuisine at various eateries in Ramnagar. The town also hosts several local markets where tourists can buy souvenirs and traditional crafts.",
    about3: "With its tranquil atmosphere and rich cultural backdrop, Ramnagar Udhampur provides a unique travel experience for those looking to explore the heritage and natural beauty of Jammu region.",
    about4: "",
    arrivalbycab1: "The journey from Jammu to Ramnagar can take around 2 to 3 hours by cab, depending on the traffic and road conditions.",
    arrivalbycab2: "Cab services are readily available in Jammu for a comfortable trip to Ramnagar. Both private and shared cab options are offered by various operators.",
    arrivalbycab3: "Advance booking for cabs can be done through online platforms or local travel agencies to ensure a hassle-free travel experience.",
    arrivalbycab4: "The drive to Ramnagar offers scenic views of the countryside and mountains, making the journey as enjoyable as the destination.",
    cimate0: "The climate in Ramnagar is temperate and varies with the seasons, providing a different experience to visitors throughout the year.",
    cimate1: "Summer in Ramnagar is warm and pleasant, ideal for exploring the outdoors and engaging in cultural activities.",
    cimate2: "The monsoon season brings lush greenery to the landscape, making it a beautiful time to visit despite the possibility of rain.",
    cimate3: "Autumn brings mild weather and clear skies, perfect for sightseeing and enjoying the natural beauty of the area.",
    cimate4: "Winter in Ramnagar is cool, with occasional snowfall in the higher regions, offering a serene and picturesque setting.",
    headingfood: "Must try in Ramnagar",
    foodsName: ['Kalari cheese', 'Rajma Chawal'],
    foodsDescription: ["Kalaris are usually made from cow's or buffalo's milk, though kalaris made from goat's milk are also available, and have a whitish color. Traditionally Kalaris are made from raw (uncooked) full fat milk that is separated using soured milk.", "A staple dish in the Jammu region, Rajma Chawal is red kidney beans cooked in a thick gravy with a blend of spices, served with steamed rice. It's a comfort food loved by locals and visitors alike."],
    headinghotels: "List Of Hotels",
    hotelsName: ["POP Umeed Homestay  ","Hotel Highway Udhampur","NA","NA" ],
    hotelsPhone: ["0124-6201611 ","084929 58150 ","NA" ,"NA"],
    hotelsViewLinks: ["https://www.oyorooms.com/189638/ ", "https://hotelhighwayudhampur.in/ ","","" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=POP Umeed Homestay, Nimbla, Near Petrol Pump, Ramnagar, Udhampur, Jammu and Kashmir 182128","https://www.google.com/maps?q=Hotel Highway Udhampur"],
    religiousPlaces: ["Pingla Devi Shrine","Narsingh Mandir"],
    religiousPlacesDescription: ["The Holy Shrine is about 6 Kms away from the village Kaua on the Domail-Ramnagar Road and is linked with the track. The Shrine consists of small cave where The divine power lies in the shape of Pindies. The cave of Pingla Mata seems a small hole from outside but it has as much space inside as to accommodate at least 500 people at a time. Though the journey is tough yet it does not tire the devotees because of scenic beauty throughout. The entire track consists of lush green pine trees which inspire the devotees to move on.","Narsingh Mandir is considered as one of the most sacred shrine of the Ramnagar Town. It is located near Old Palace, Ramnagar. The locals have deep faith in Narsingh Ji Maharaj."],
    historicalPlaces: ["Raja Ram Singh Glass Palace ( Sheesh Mehal ) "],
    historicalPlacesDescription: ["The Shish Mahal complex was built by Raja Ram Singh (AD 1801-1844). It comprises two forecourts having Burjis at corners. Behind these are halls and rooms flanked on either side of the central passage. To the left of the entrance are Darbar Hall, Shish Mahal and Rang Mahal. To the right is an arcaded verandah with rooms in the rear. The Darbar hall has scenes of mural paintings depicting themes from the Ramayana, Bhagwata and other Puranas, besides battle scenes connected with Raja Suchet Singh. "],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default Ramnagar;