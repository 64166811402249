import React from "react";
import carouselImg from '../img/carousel-11.jpg';

function App() {
  return (
    <div>
              {/* <!-- Carousel Start --> */}
    <div class="container-fluid p-0">
        <div id="header-carousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="w-100" src={carouselImg} alt="Carousal" />
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3 max-width-900" >
                            <h4 class="text-white text-uppercase mb-md-3">Discover </h4>
                            <h1 class="display-3 text-white mb-md-4"> Jammu & Kashmir </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    {/* <!-- Carousel End --> */}
     </div>
     );
    }
    
    export default App;