// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/mantai-lake.jpg";
import baderwah2 from "../../img/mantalia.jpg";
import religiousImage1 from "../../img/siyad-baba1.jpg";
import religiousImage2 from "../../img/sudh mahadev.jpg";
import historicalImage1 from "../../img/ramnagar fort.jpg";
import historicalImage2 from "../../img/shankar devta.jpg";
import FoodsImage3 from "../../img/kaladi kulcha.jpg";
import FoodsImage4 from "../../img/kulthi di dal.jpg";


const mantaliaLake = {
    name: "Mantalai Lake",
    
    description: "Mantalai Lake",
    linkMap:"https://www.google.com/maps?q=Mantalai, 182142",
    description1: "Nestled in the heart of the Pir Panjal range, Mantalai Lake stands as a testament to the untouched beauty of the Jammu and Kashmir region. Tucked away from the bustling tourist routes, this pristine lake offers a serene haven for those seeking a communion with nature. Surrounded by towering snow-capped peaks, the lake exudes a sense of tranquility that captivates visitors. The journey to Mantalai is not just a trek but an odyssey through dense forests, meadows adorned with vibrant wildflowers, and surreal landscapes that seem to change with every step.",
    p1: "As you ascend towards Mantalai, the air becomes crisper, the surroundings more enchanting, and the anticipation of  reaching the secluded lake intensifies. The area's remoteness adds to the allure, providing a genuine escape from the noise of modern life. Mantalai is not just a destination; it's a sanctuary for those seeking solace amidst the unspoiled grandeur of the Himalayas.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1,baderwah2], // Add images array
    distanceFromSrinagar: "The distance from Jammu to Udhampur, both located in the Indian union territory of Jammu and Kashmir, is approximately 65 kilometers (40 miles) by road. This distance can vary slightly depending on the specific route taken and any detours along the way. Traveling by car or bus, the journey typically takes around 1.5 to 2 hours, depending on traffic conditions and road quality.",
    about1: "It is believed that Goddess Parvati was born here and got married to Lord Shiva at the same place. There is a divine rock that is said to be a sign of their marriage. An ancient temple of Lord Shiva, Aparneswar, is located near Mantalai lake.",
    about2: " ",
    about3: " ",
    about4: " ",
    arrivalbycab1: " Pre-Booked Cab: You can pre-book a cab or taxi service in Jammu to take you to Udhampur. Many cab operators and travel agencies in Jammu offer cab services for intercity travel. Booking in advance ensures availability and allows you to negotiate the fare and itinerary. Arriving in Udhampur by cab from Jammu is a common and convenient option for travelers. Here's how you can arrange your journey. ",
    arrivalbycab2: " Local Taxi Stands: In Jammu, you can find local taxi stands or cab operators that provide transportation services to Udhampur. You can approach them directly to hire a cab for your journey. Make sure to negotiate the fare and confirm the details of the trip beforehand. ",
    arrivalbycab3: "Local Taxi Stands: In Jammu, you can find local taxi stands or cab operators that provide transportation services to Udhampur. You can approach them directly to hire a cab for your journey. Make sure to negotiate the fare and confirm the details of the trip beforehand. ",
    arrivalbycab4: " ",
    cimate1: "Summers are generally mild, making it an ideal time for trekking and exploration.  ",
    cimate2: " The region may witness snowfall during winters, adding a magical touch to the already breathtaking surroundings.",
    cimate3: " ",
    cimate4: " ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Highway Udhampur","NA", ],
    hotelsPhone: ["084929 58150","NA", ],
    hotelsViewLinks: ["http://hotelhighwayudhampur.in/","NA", ],
    hotelsMapsLinks: ["Hotel Highway Udhampur, 2, National Highway, near New Bus Stand, Indra Nagar, Udhampur, Ladan, Jammu and Kashmir 182101","NA", ],
    headingfood: "Must try in Mantalai Lake",
    foodsName: ['kaladi kulcha', 'Kulthein di Dal',"Kulthein di Dal"],
    foodsDescription: [ " Kaladi kulcha is a traditional dish from Udhampur. district of Jammu, kaladi's are basically matured. cheese, served with the choice of your bread but. preferably kulcha's."," Cook Kulthi dal with vegetables like carrots, onions, and tomatoes, and add some spices like cumin, turmeric, and coriander. Puree the mixture and enjoy a warm and comforting kulthi Dal"],
    religiousPlaces: ["Siar Baba.","Sudh Mahadev"],
    religiousPlacesDescription: ["There is a small temple of holy shrine of Siarh baba (Shiva) beside the waterfall."," The temple has been named after a demon called Sudheet, who once came to visit Goddess Parvati. On seeing him, the goddess screamed and on an impulse Lord Shiva killed him with his trident. On realising his mistake, Lord Shiva resurrected him and thus the temple bears his name."],
    historicalPlaces: ["Ramnagar Fort.","Shankri Devta"],
    historicalPlacesDescription: ["The fort is built in red sandstone and has a temple and a museum within the grounds and the temple is dedicated to Ved Vyasa, who wrote Mahabharata, the great Indian epic. The Ramnagar fort museum displays a Royal collection which includes vintage Cars, palanquins, swords and old guns, ivory work and antique clocks.",'Shankri Devta temple is situated on a hillock in village Meer of block Panchari in a scenic spot which is surrounded by meadows, lofty mountains and lush green forests. Village Meer and Block Headquarter, Panchari are linked with pucca road from Udhampur town.'],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [ religiousImage1,religiousImage2], // Add images array for religious
    imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default mantaliaLake;