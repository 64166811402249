import pariMahalImage1 from "../../img/pari-mahal.jpg";
import pariMahalImage2 from "../../img/parim-mahal2.webp";
import religiousImage1 from "../../img/merak shah.jpg";
import religiousImage2 from "../../img/hazratbal.jpg";
import historicalImage1 from "../../img/cheshma-shahi.webp";
import historicalImage2 from "../../img/Dal-Lake.jpg";
import FoodsImage3 from "../../img/mutton-roganjosh.webp";
import FoodsImage4 from "../../img/yakhni.jpg";
import FoodsImage5 from "../../img/gushtaba.jpg";

const pariMahal = {
  name: "Pari Mahal",
  description: "Pari Mahal",
  linkMap: "https://www.google.com/maps?q=Pari Mahal, Srinagar",
  description1: "Pari Mahal, also known as 'The Palace of Fairies,' is a historic monument located in Srinagar, Jammu and Kashmir, India. Situated atop the Zabarwan mountain range, it offers breathtaking views of the city of Srinagar and the Dal Lake.",
  p1: "Built in the mid-17th century by Dara Shikoh, the eldest son of Mughal Emperor Shah Jahan, Pari Mahal served as a Sufi observatory and residence for scholars. It features beautiful terraced gardens and stunning architecture.",
  images: [pariMahalImage1, pariMahalImage2], // Add images array
  distanceFromSrinagar: "Pari Mahal is located approximately 5 kilometers west of the city center of Srinagar, making it easily accessible by road.",
  about1: "The architectural style of Pari Mahal reflects a blend of Islamic and Persian influences. Its terraced gardens are adorned with colorful flowers and provide a serene environment for visitors to explore.",
  about2: "Pari Mahal is not only a historical site but also a center for spiritual and intellectual pursuits. It attracts tourists and scholars alike, offering insights into Mughal-era architecture and Sufi traditions.",
  about3: "Visitors to Pari Mahal can enjoy panoramic views of Srinagar and the surrounding mountains. It is a popular destination for nature lovers, photographers, and history enthusiasts.",
  about4: "Pari Mahal is a historic monument located in Srinagar, Jammu and Kashmir, India. Built in the mid-17th century by Mughal Prince Dara Shikoh, it served as a Sufi observatory and residence. The palace is known for its stunning architecture and terraced gardens, offering panoramic views of Srinagar and the Dal Lake. Pari Mahal is a testament to the rich cultural heritage of the region and attracts visitors from all over the world.",
  arrivalbycab1: "To reach Pari Mahal in Srinagar by cab, you can follow these steps:",
  arrivalbycab2: "Find Local Transport Options: Look for local cab services or taxi stands in Srinagar. You can search online, ask your accommodation provider for recommendations, or inquire with locals.",
  arrivalbycab3: "Booking: Once you have identified a suitable cab service, proceed to book your cab. Provide them with details such as your name, pickup location, desired pickup time, and any specific requirements you may have.",
  arrivalbycab4: "The cab should pick you up from your designated location in Srinagar and transport you to Pari Mahal.",
  cimate0: "The climate around Pari Mahal in Srinagar, Jammu and Kashmir, varies throughout the year, with distinct seasons.",
  cimate1: "Summer (June to August): The summer months are mild and pleasant, with temperatures ranging from around 10°C to 30°C (50°F to 86°F). It is an excellent time to visit Pari Mahal as the gardens are in full bloom.",
  cimate2: "Autumn (September to November): Autumn brings cooler temperatures and vibrant foliage colors, making it a picturesque time to visit the monument.",
  cimate3: "Winter (December to February): Winter in Srinagar is cold, with temperatures often dropping below freezing. Snowfall is common during these months, adding to the beauty of Pari Mahal.",
  cimate4: "Spring (March to May): Spring is a delightful time to visit Pari Mahal as the weather gradually warms up, and the gardens come to life with blossoming flowers.",
  headinghotels: "List Of Hotels",
  hotelsName: ["Hotel Grand Mamta", "Vivanta Dal View, Srinagar", "The LaLiT Grand Palace Srinagar", "Hotel City Plaza"],
  hotelsPhone: ["097969 99306", "01942461111", "01942501001", "NA"],
  hotelsViewLinks: ["http://thegrandmamta.in/", "https://www.vivantahotels.com/en-in/dal-view-srinagar/?utm_source=google&utm_medium=local&utm_campaign=vivanta_dal_view_cla&utm_content=vivanta_dal_view_gmb", "http://www.thelalit.com/the-lalit-srinagar/", "NA"],
  hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel The Grand Mamta, 3RMF+HRM, Near Temple, Khonakhan, Dalgate Bridge, Srinagar, Jammu and Kashmir 190001", "https://www.google.com/maps?q=Kralsangri, Brein, Srinagar, Jammu and Kashmir 191121", "https://www.google.com/maps?q=Gupkar Rd, Srinagar, Jammu and Kashmir 190001", "https://www.google.com/maps?q=Durghanag Road, Dalgate Bridge, Munshi Bagh, Srinagar, Jammu and Kashmir 190001"],
  headingfood: "Must-Try in Srinagar",
  foodsName: ['Rogan Josh', 'Yakhni', 'Gushtaba'],
  foodsDescription: ["Rogan Josh is a signature Kashmiri dish made with tender mutton cooked in a rich gravy of yogurt, spices, and saffron. It is best enjoyed with steamed rice or Kashmiri naan.",
    "Yakhni is a traditional Kashmiri dish made with tender pieces of meat (usually chicken or lamb) cooked in a yogurt-based gravy with aromatic spices like fennel, cardamom, and cinnamon. It is a comforting and flavorful dish often served with rice.",
    "Gushtaba is a delectable Kashmiri meatball curry made with minced mutton balls cooked in a flavorful yogurt-based gravy. It is rich, creamy, and bursting with traditional Kashmiri spices."],
  religiousPlaces: ["Sayyid Meerakh Shah Kashani","Hazratbal Shrine"],
  religiousPlacesDescription: ["Sayyid Mirak Shah Kashani was a poet of substance who wrote devotional poems in Kashmiri language. The theme of his verses was Toheed (monotheism) and Ishq-e-Rasool (love of the Messenger of Allah)","The Hazratbal Shrine, popularly called Dargah Sharif, is a Muslim shrine located in Hazratbal locality of Srinagar in Jammu and Kashmir, India. It contains a relic, Moi-e-Muqqadas, the blessed hair of the Islamic prophet Muhammad"],
  historicalPlaces: ["Chashma Shahi","Dal – Lake"],
  historicalPlacesDescription: [" Chashma Shahi, is a famous Mughal garden located in Srinagar, the summer capital of Jammu and Kashmir, India. It is situated near the northeast of Dal Lake. The garden was built in 1632 AD by Ali Mardan Khan, who was the governor of Emperor Shah Jahan. Cheshmashahi is renowned for its beautiful terraced lawns, stunning fountains, and the natural spring of freshwater, which is believed to have medicinal properties. The garden offers breathtaking views of the surrounding mountains and is a popular tourist attraction in Srinagar","Dal lake, originally known as Sureshvari Dal, is a lake in Srinagar, the summer capital of the Indian-administered Jammu and Kashmir in the disputed Kashmir region. It is an urban lake the second largest lake in Jammu and Kashmir, and the most visited place in Srinagar by tourists and locals. It is integral to tourism and recreation in the Kashmir valley and is variously known as the Lake of Flowers”."],
  foodsImages: [FoodsImage3,FoodsImage4,FoodsImage5],
  imagesforreligious: [religiousImage1,religiousImage2],
  imagesforhistorical: [historicalImage1,historicalImage2]
};

export default pariMahal;

